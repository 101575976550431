<script>
import Layout from "@/router/layouts/store";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
      'Order': 'Pedido',
      'Payment Method': 'Forma de Pagamento',
      'Order Date': 'Data do Pedido',

      'Items': 'Itens',
      'Item': 'Item',
      'Price': 'Preço',
      'Qty': 'Qtd',
      'Value': 'Valor',
      'Status': 'Status',
      'Date': 'Data',

      'Switch plans': 'Trocar de Plano',
      'Current choice': 'Escolha Atual',
      'Choose': 'Escolher',
      'Plan name': 'Nome do Plano',
      'Plans': 'Planos',

      'Awaiting payment': 'Aguardando pagamento',
      'Payment confirmed': 'Pagamento confirmado',

      'Address': 'Endereço',
      'Send exactly value to make payment': 'Envie exatamente o valor para efetuar o pagamento',

      'The order was not found.': 'O pedido não foi encontrado.',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Order': 'Pedido',
      'Payment Method': 'Forma de Pago',
      'Order Date': 'Data do Pedido',

      'Items': 'Itens',
      'Item': 'Item',
      'Price': 'Preço',
      'Qty': 'Qtd',
      'Value': 'Valor',
      'Status': 'Status',
      'Date': 'Data',

      'Switch plans': 'Trocar de Plano',
      'Current choice': 'Escolha Atual',
      'Choose': 'Escolher',
      'Plan name': 'Nome do Plano',
      'Plans': 'Planos',

      'Awaiting payment': 'En espera de pago',
      'Payment confirmed': 'Pago confirmado',

      'Address': 'Dirección',
      'Send exactly value to make payment': 'Enviar exactamente el valor de hacer el pago',

      'The order was not found.': 'O pedido não foi encontrado.',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      loading: true,
      errored: false,
      empty: false,
      plans: null,

      order: {
        id: this.$route.params.id,
        voucher: false,
        date: null,

        subtotal: '0.00',
        discount: '0.00',
        shipping: {
          method: '',
          value: '0.00',
          address: {
            zipcode: '',
            address: '',
            number: '',
            complement: '',
            district: '',
            city: '',
            state: '',
          }
        },
        payment: {
          methods: [],
          list: [],
        },

        total: '0.00',

        items: null,
        status: null,
      },

      voucher: {
        loading: false,
        alert: {
          type: '',
          message: '',
        },
        code: '',
      },

      balance: {
        modal: false,
        loading: false,
        pay: '0.00',
        total: '0.00',
      },

      cashback: {
        modal: false,
        loading: false,
        total: '0.00',
      },
    };
  },
  validations: {
    voucher: {
      code: { required },
    }
  },
  methods: {
    getOrder() {
      if (this.order.id) {
        api
          .get('store/orders/'+this.order.id)
          .then(response => {
            if (response.data.status=='success') {
              this.order = response.data.order

              this.getBalance();
              this.getCashBack();

              this.loading = false
            } else {
              this.empty = true
              this.message = response.data.message
            }
          })
          .catch(error => {
            this.errored = error
          })
      }
    },
    voucherSubmit() {
      if (this.voucher.code) {
        this.voucher.loading = true

        api
          .post('wallet/order/voucher/'+this.order.id, {
            code: this.voucher.code,
          })
          .then(response => {
            if (response.data.status=='success') {
              this.getOrder()
              this.voucher.loading = false
            } else {
              this.voucher.alert.type = 'alert-danger'
              this.voucher.alert.message = response.data.message
              this.voucher.loading = false
            }
          })
      }
    },
    getBalance() {
      api
        .get('wallet/balance/network')
        .then(response => {
          if (response.data.status == 'success') {
            this.balance.total = response.data.balance
            this.balance.pay = parseFloat(this.balance.total) - parseFloat(this.order.total);
          }
        })
    },
    payOrder() {
      this.balance.loading = true;

      api
        .post('wallet/pay/' + this.order.id)
        .then(response => {
          if (response.data.status == 'success') {
            this.$toast.success('O pedido foi pago com sucesso.')
            this.getOrder();
          }
        })
        .catch(error => {
          if (error) {
            this.balance.loading = false;
          }
        })
        .finally(() => {
          this.balance.loading = false;
        })
    },
    getCashBack() {
      api
        .get('cashback/balance')
        .then(response => {
          if (response.data.status == 'success') {
            this.cashback.total = response.data.balance
          }
        })
    },
    payCashBack() {
      this.cashback.loading = true;

      api
        .post('cashback/pay/' + this.order.id)
        .then(response => {
          if (response.data.status == 'success') {
            this.$toast.success('O cashback foi utilizado com sucesso.')
            this.getOrder();
          }
        })
        .catch(error => {
          if (error) {
            this.cashback.loading = false;
          }
        })
        .finally(() => {
          this.cashback.loading = false;
        })
    }
  },
  mounted() {
    this.getOrder()
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Order') }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="card">
          <div class="card-body p-4">
            <div v-if="errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="empty">{{ t('No records found.') }}</div>
            <div v-else class="p-2">
              <div class="d-flex">
                <h4 class="mb-0 font-size-15 flex-fill">
                  #{{ order.id }}
                </h4>
                <h4 class="mb-0 font-size-15 flex-fill text-right">
                  {{ order.date }}
                </h4>
              </div>
              <hr />
              <div class="table-responsive">
                <table class="table table-nowrap">
                  <thead>
                    <tr>
                      <th class="border-0 text-uppercase">{{ t('Item') }}</th>
                      <th class="border-0 text-uppercase">{{ t('Price') }}</th>
                      <th class="border-0 text-uppercase">{{ t('Qty') }}</th>
                      <th class="border-0 text-uppercase">{{ t('Total') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(td,index) in order.items" :key="index">
                      <td>{{ td.name }} </td>
                      <td>{{ td.price | currency }}</td>
                      <td>{{ td.quantity }}</td>
                      <td>{{ td.subtotal | currency }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row mt-5">
                <div class="col-lg-6 mb-4">
                  <div v-if="order.shipping.address.zipcode">
                    <h3 v-if="order.type == 'receive'" class="font-size-16 mb-3">{{ t('Endereço de entrega') }}</h3>
                    <h3 v-else class="font-size-16 mb-3">{{ t('Endereço de retirada') }}</h3>
                    {{ order.shipping.address.address }},
                    {{ order.shipping.address.number }},
                    <span v-if="order.shipping.address.complement">{{ order.shipping.address.complement }}, </span>
                    {{ order.shipping.address.district }}<br>
                    {{ order.shipping.address.city }}/{{ order.shipping.address.state }},
                    {{ order.shipping.address.zipcode }}
                  </div>
                  <a v-if="order.shipping.tracking && order.shipping.tracking.code" target="_blank" :href="order.shipping.tracking.url" class="btn btn-default btn-sm mt-1 p-2 align-middle">
                    <i class="bx bxs-truck font-size-17 text-white mr-1 align-top"></i>
                    Rastrear pedido
                  </a>
                </div>
                <div class="col-lg-6 mb-4">
                  <h3 class="font-size-16 mb-3">{{ t('Detalhes do pagamento') }}</h3>
                  <div class="table-responsive">
                    <table class="table table-nowrap table-borderless">
                      <tbody>
                        <tr v-if="order.subtotal != order.total">
                          <td class="px-0 py-1">{{ t('SUBTOTAL') }}</td>
                          <td class="px-0 py-1 text-right">{{ order.subtotal | currency }}</td>
                        </tr>
                        <tr>
                          <td class="px-0 py-1">{{ t('FRETE') }} — {{ order.shipping.method }}</td>
                          <td class="px-0 py-1 text-right">+ {{ order.shipping.value | currency }}</td>
                        </tr>
                        <tr v-if="order.discount != '0.00'">
                          <td class="px-0 py-1">{{ t('DESCONTO') }}</td>
                          <td class="px-0 py-1 text-right">- {{ order.discount | currency }}</td>
                        </tr>
                        <tr>
                          <td class="px-0 py-1 font-weight-bold">{{ t('TOTAL') }}</td>
                          <td class="px-0 py-1 font-weight-bold text-right">{{ order.total | currency }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <h3 class="font-size-16 mb-2">{{ t('Status') }}</h3>
              <div v-for="(td,index) in order.status" :key="index">
                {{ td.date }} — {{ t(td.status) }}
              </div>
              <b-tabs v-if="order.payment.status === 'pending'" justified nav-class="nav-tabs-custom mt-5" content-class="p-3 text-muted">
                <b-tab v-for="(pay,method) in order.payment.methods" :key="method">
                  <template v-slot:title>{{ pay.name }}</template>
                  <div v-if="method == 'PIX'" class="text-center">
                    <img style="width: 250px;" :src="pay.qrcode" />
                    <p>Leia o qrcode acima ou</p>
                    <ol class="text-left">
                      <li>Abra o app do seu banco ou seu app de pagamentos.</li>
                      <li>Busque a opção de pagar com pix.</li>
                      <li>Copie e cole o seguinte código.</li>
                      <li>Após o pagamento envie o comprovante da tranferência.</li>
                    </ol>

                    <div class="border rounded p-3 mb-3 text-left">
                      {{ pay.copypaste }}
                    </div>

                    <button class="btn btn-default" v-clipboard:copy="pay.copypaste"><i class="bx bx-copy font-size-18 align-middle"></i> Copiar</button>
                  </div>
                  <div v-else-if="method == 'pagseguro'" class="text-center">
                    <img class="mt-1 mb-4" height="50px" src="@/assets/images/payments/logo-pagseguro.png"><br>
                    <a target="_blank" class="btn btn-default btn-lg btn-block text-uppercase" :href="pay.url">
                      Clique aqui para pagar
                      <svg class="align-top" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" style="fill: rgba(255, 255, 255, 1);">
                        <path d="m13 3 3.293 3.293-7 7 1.414 1.414 7-7L21 11V3z"></path>
                        <path d="M19 19H5V5h7l-2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-5l-2-2v7z"></path>
                      </svg>
                    </a>
                  </div>
                  <div v-else-if="method == 'pagarme'" class="text-center">
                    <img class="mt-1 mb-4" height="50px" src="@/assets/images/payments/logo-pagarme.svg"><br>
                    <a target="_blank" class="btn btn-default btn-lg btn-block text-uppercase" :href="pay.url">
                      Clique aqui para pagar
                      <svg class="align-top" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" style="fill: rgba(255, 255, 255, 1);">
                        <path d="m13 3 3.293 3.293-7 7 1.414 1.414 7-7L21 11V3z"></path>
                        <path d="M19 19H5V5h7l-2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-5l-2-2v7z"></path>
                      </svg>
                    </a>
                  </div>
                </b-tab>
                <b-tab key="balance" :active="balance.pay >= 0">
                  <template v-slot:title>Saldo em Conta</template>
                  <div class="mt-4 text-center">
                    <template v-if="balance.pay > 0">
                      <p>Deseja pagar esse pedido com o seu saldo?</p>
                      <p class="mb-4">Seu saldo atual é de <strong>{{ balance.total | currency }}</strong> e seu saldo após o pagamento será de <strong>{{ balance.total - order.total | currency
                          }}</strong>.</p>
                      <button class="btn btn-default btn-lg btn-block text-uppercase mr-3" v-on:click="payOrder()">
                        Confirmar
                        <b-spinner v-if="balance.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                      </button>
                    </template>
                    <template v-else>
                      <p>No momento você não possui saldo suficiente para realizar o pagamento do pedido.</p>
                    </template>
                  </div>
                </b-tab>
                <b-tab key="cashback" :active="cashback.total > 0">
                  <template v-slot:title>CashBack</template>
                  <div class="mt-4 text-center">
                    <template v-if="cashback.total > 0">
                      <p>Deseja utilizar o seu saldo de CashBack para este pedido?</p>
                      <p class="mb-4">Seu saldo atual é de <strong>{{ cashback.total | currency }}</strong>.</p>
                      <button class="btn btn-default btn-lg btn-block text-uppercase mr-3" v-on:click="payCashBack()">
                        Confirmar
                        <b-spinner v-if="cashback.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                      </button>
                    </template>
                    <template v-else>
                      <p>No momento você não possui saldo de cashback.</p>
                    </template>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>