<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import moment from "moment";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";
import ChartGraduation from "@/components/widgets/chart-graduation.vue";
import ChartBonus from "@/components/widgets/chart-bonus";
import ChartPoints from "@/components/widgets/chart-points";
import ChartOrders from "@/components/widgets/chart-orders";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Banners,
    Stat,
    ChartGraduation,
    ChartBonus,
    ChartPoints,
    ChartOrders
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      month_year: moment().format("MM/YY"),

      monthly: "inactive",

      total: {
        prediction: "R$ -,--",
        bonus: "R$ -,--",
        balance: "R$ -,--",
      },

      cashback: null,

      graduation: {
        id: null,
        name: '-',
        image: null
      },

      statData: null,
      wallets: null,

      cart: {
        items: {
          loading: true,
          total: 0,
          list: null
        },
        points: {
          unilevel: 0,
          graduation: 0,
        },
        subtotal: '0.00',
        sale: '0.00',
        shipping: {
          method: '-',
          value: '-',
        },
        total: '0.00'
      },

      referral: {
        sponsored: process.env.VUE_APP_URL_PAINEL + this.$store.state.account.user.username,
        ecommerce: 'https://' + this.$store.state.account.user.username + '.' + process.env.VUE_APP_URL_ECOMMERCE
      }
    };
  },
  methods: {
    getMonthly() {
      api.get("reports/monthly").then((response) => {
        this.monthly = response.data.status;
        this.getStatData();
      });
    },
    getCashBack() {
      api.get("reports/cashback").then((response) => {
        this.cashback = response.data.cashback;
      });
    },
    getGraduation() {
      api
        .get('reports/graduation')
        .then(response => {
          if (response.data.status == 'success') {
            this.graduation.id = response.data.current.id
            this.graduation.name = response.data.current.name
            this.graduation.image = response.data.current.image
          }
        })
    },
    getTotalPrediction() {
      api.get("reports/bonus/prediction").then((response) => {
        if (response.data.status == "success") {
          this.total.prediction = this.$options.filters.currency(
            response.data.total
          );
          this.getStatData();
        }
      });
    },
    getTotalBonus() {
      api
        .get('reports/bonus/total')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.bonus = this.$options.filters.currency(response.data.total)
            this.getStatData();
          }
        })
    },
    getTotalBalance() {
      api
        .get('wallet/balance/network')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.balance = this.$options.filters.currency(response.data.balance)
            this.getStatData();
          }
        })
    },
    getStatData() {
      this.statData = [
        {
          type: "line",
          title: "Plano",
          value: this.account.user.package.name,
          icon: "bx-package"
        },
        {
          type: "line",
          icon: "bx-time-five",
          title: "Status",
          value: this.monthly,
          month: true,
        },
        {
          type: "line",
          icon: "bxs-graduation",
          title: "Graduação",
          value: this.graduation.name,
        },
        {
          type: "line",
          icon: "bx-line-chart",
          title: "Previsão de Bônus",
          value: this.total.prediction,
          month: true,
        },
        {
          type: "line",
          icon: "bx-dollar",
          title: "Total de Bônus",
          value: this.total.bonus,
        },
        {
          type: "line",
          icon: "bx-wallet",
          title: "Saldo Disponível",
          value: this.total.balance,
        }
      ]
    },
    getCart() {
      if (!localStorage.cart) {
        api
          .get('store/cart')
          .then(response => {
            if (response.data.status == 'success') {
              this.setStorage(response.data)
            }
          })
          .catch(error => {
            this.cart.errored = error
          })
      } else {
        this.cart = JSON.parse(localStorage.cart)
      }
    },
    setStorage(data) {
      this.cart.items.total = data.items.total
      this.cart.items.list = data.items.list
      this.cart.subtotal = data.subtotal
      this.cart.sale = data.sale
      this.cart.shipping = data.shipping
      this.cart.total = data.total

      localStorage.cart = JSON.stringify(this.cart)
    },
  },
  mounted() {
    this.getMonthly()
    this.getCashBack()
    this.getGraduation()
    this.getTotalPrediction()
    this.getTotalBonus()
    this.getTotalBalance()
    this.getStatData()
    this.getCart()
  }
};
</script>

<template>
  <Layout>
    <router-link v-if="cart.items.total > 0" to="/store/cart" class="text-dark">
      <div class="card">
        <div class="card-body bg-soft-warning rounded p-0">
          <div class="d-flex align-items-center">
            <img class="rounded-circle header-profile-user m-2 mx-2" :src="cart.items.list[Object.keys(cart.items.list)[0]].image" width="30px" height="30px">
            <div class="py-3">Você deixou alguns produtos em seu carrinho, clique aqui para finalizar o seu pedido!</div>
          </div>
        </div>
      </div>
    </router-link>

    <Banners></Banners>

    <div class="card mb-2">
      <div class="card-body p-1 d-flex justify-content-between align-items-center">
        <div class="mr-2">
          <div class="bg-light rounded p-2">
            <i class="bx bx-link-alt font-size-24 align-middle"></i>
          </div>
        </div>
        <h5 class="flex-fill m-0 align-items-center font-size-16">
          Link de indicação
        </h5>
        <div class="d-flex align-items-center">
          <div class="ml-1">
            <ShareNetwork network="whatsapp" url="" :title="referral.sponsored">
              <div class="bg-light rounded p-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: #0f0d0d;">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263">
                  </path>
                </svg>
              </div>
            </ShareNetwork>
          </div>
          <div class="ml-1">
            <button class="btn btn-link p-0 m-0" v-clipboard:copy="referral.sponsored
              " v-on:click="$toast.success('Copiado!');">
              <div class="bg-light rounded p-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: #0f0d0d;">
                  <path
                    d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z">
                  </path>
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body p-1 d-flex justify-content-between align-items-center">
        <div class="mr-2">
          <div class="bg-light rounded p-2">
            <i class="bx bx-link-alt font-size-24 align-middle"></i>
          </div>
        </div>
        <h5 class="flex-fill m-0 align-items-center font-size-16">
          Link do e-commerce
        </h5>
        <div class="d-flex align-items-center">
          <div class="ml-1">
            <ShareNetwork network="whatsapp" url="" :title="referral.sponsored">
              <div class="bg-light rounded p-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: #0f0d0d;">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263">
                  </path>
                </svg>
              </div>
            </ShareNetwork>
          </div>
          <div class="ml-1">
            <button class="btn btn-link p-0 m-0" v-clipboard:copy="referral.ecommerce
              " v-on:click="$toast.success('Copiado!');">
              <div class="bg-light rounded p-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: #0f0d0d;">
                  <path
                    d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z">
                  </path>
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-6 col-lg-4">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" :month="stat.month" />
      </div>
    </div>

    <fieldset class="pb-0">
      <legend>
        CashBack
      </legend>

      <div v-if="!cashback" class="text-center mb-4">
        <b-spinner class="align-middle" variant="default" role="status"></b-spinner>
      </div>
      <div v-else>
        <div class="row justify-content-center">
          <div class="col-12 col-md-12 col-lg-9 mb-3">
            <b-progress style="border-radius: 3px;" :value="Number(cashback.progress)" variant="default" height="25px"></b-progress>
            <div class="d-flex justify-content-between font-size-12">
              <div>
                0% – R$ 0,00
              </div>
              <div class="text-center">
                5% – R$ 500,00
              </div>
              <div>
                10% – R$ 1.000,00
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-12 col-md-4 col-lg-3 mb-3">
            <div class="d-flex align-items-center">
              <div>
                <div class="bg-light rounded py-1 px-2">
                  <i class="bx bx-user font-size-24 my-1 align-middle"></i>
                </div>
              </div>
              <div class="flex-fill ml-2">
                <h5 class="mb-0 notranslate font-weight-bold">{{ cashback.orders | currency }}</h5>
                <p class="font-weight-medium mb-0 text-uppercase font-weight-light font-size-12">Compras Pessoais</p>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-3 mb-3">
            <div class="d-flex align-items-center">
              <div>
                <div class="bg-light rounded py-1 px-2">
                  <i class="bx bx-calculator font-size-24 my-1 align-middle"></i>
                </div>
              </div>
              <div class="flex-fill ml-2">
                <h5 class="mb-0 notranslate font-weight-bold">{{ cashback.percent }}</h5>
                <p class="font-weight-medium mb-0 text-uppercase font-weight-light font-size-12">% de CashBack</p>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-3 mb-3">
            <div class="d-flex align-items-center">
              <div>
                <div class="bg-light rounded py-1 px-2">
                  <i class="bx bx-dollar font-size-24 my-1 align-middle"></i>
                </div>
              </div>
              <div class="flex-fill ml-2">
                <h5 class="mb-0 notranslate font-weight-bold">{{ cashback.bonus | currency }}</h5>
                <p class="font-weight-medium mb-0 text-uppercase font-weight-light font-size-12">CashBack</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>

    <div class="row">
      <div class="col-md-12 col-lg-6">
        <fieldset class="card-h-fix">
          <legend>
            Graduação <sup class="font-weight-light">{{ month_year }}</sup>
          </legend>
          <div class="d-flex justify-content-center card-h-fix">
            <ChartGraduation />
          </div>
        </fieldset>
      </div>
      <div class="col-md-12 col-lg-6">
        <fieldset class="card-h-fix">
          <legend>
            Bonificações <sup class="font-weight-light">{{ month_year }}</sup>
          </legend>
          <div class="d-flex justify-content-center card-h-fix">
            <ChartBonus />
          </div>
        </fieldset>
      </div>
      <div class="col-md-12 col-lg-6">
        <fieldset class="card-h-fix">
          <legend>
            Pontuações <sup class="font-weight-light">{{ month_year }}</sup>
          </legend>
          <div class="d-flex justify-content-center card-h-fix">
            <ChartPoints />
          </div>
        </fieldset>
      </div>
      <div class="col-md-12 col-lg-6">
        <fieldset class="card-h-fix">
          <legend>
            Pedidos <sup class="font-weight-light">{{ month_year }}</sup>
          </legend>
          <div class="d-flex justify-content-center card-h-fix">
            <ChartOrders />
          </div>
        </fieldset>
      </div>
    </div>
  </Layout>
</template>