<script>
import Chart from "@/components/widgets/chart";
import { api } from "@/state/services";

export default {
  components: { Chart },
  data() {
    return {
      empty: true,

      resume: {
        binary: 0,
        graduation: 0,
        unilevel: 0
      },

      chartdata: {},
      options: {}
    }
  },
  methods: {
    getChart() {
      api
        .get('chart/points')
        .then(response => {
          if (response.data.status == 'success') {
            this.resume = response.data.chart.resume

            this.chartdata = {
              labels: response.data.chart.labels,
              datasets: [
                {
                  label: 'Graduação',
                  backgroundColor: '#f38a37',
                  data: response.data.chart.data.graduation,
                },
                {
                  label: 'Unilevel',
                  backgroundColor: '#01bb00',
                  data: response.data.chart.data.unilevel,
                }
              ]
            }

            this.options = {
              defaultFontColor: '#0f0d0d',
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    ticks: {
                      maxTicksLimit: 10,
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      max: response.data.chart.max,
                      min: 0,
                    },
                  },
                ],
              },
            }

            this.empty = false
          } else {
            this.empty = true
          }
        })
    }
  },
  mounted() {
    this.getChart();
  },
}
</script>

<template>
  <div class="w-100">
    <div class="d-flex flex-wrap flex-gap-10 mb-4">
      <div class="flex-fill">
        <div class="card mb-0">
          <div class="card-body text-center p-2">
            <h5 class="my-1">{{ resume.graduation | points }}</h5>
            <div class="bg-light rounded py-1 font-size-12 text-uppercase">Graduação</div>
          </div>
        </div>
      </div>
      <div class="flex-fill">
        <div class="card mb-0">
          <div class="card-body text-center p-2">
            <h5 class="my-1">{{ resume.unilevel | points }}</h5>
            <div class="bg-light rounded py-1 font-size-12 text-uppercase">Unilevel</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="empty"></div>
    <div v-else>
      <Chart :chartdata="chartdata" :options="options"></Chart>
    </div>
  </div>
</template>